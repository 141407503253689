<template>
    <div class="data-container">
        <div class="data-head">
            <div class="data-head-title hidden-xs-only">现场监控数据看板</div>
            <div class="data-head-name">{{data && data.name}}</div>
            <div class="data-head-time">{{ nowTime }}</div>
        </div>
        <el-row class="statistics-head">
            <el-col :span="6">
                <div class="statistics-item">
                    <div class="num">{{ data && data.totalSoldNum || 0 }}</div>
                    <div class="name">总票数</div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistics-item">
                    <div class="num">{{ data && data.realNameBindNum || 0 }}</div>
                    <div class="name">实名绑定人数</div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistics-item">
                    <div class="num">{{ data && data.bindRatio || "0%" }}</div>
                    <div class="name">实名绑定比例</div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="statistics-item">
                    <div class="num">{{ data && data.entranceRatio || "0%" }}</div>
                    <div class="name">入场比例</div>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :lg="12">
                <el-row :gutter="10">
                    <el-col :md="12">
                        <div class="group">
                            <div class="box box1">
                                <div class="d1">
                                    <img src="@/assets/img/entrance/icon_1.png" />
                                    <div>
                                        <div class="num">
                                            {{num1_1}}
                                            <!-- <vue-count-to :start-val="num1_0" :end-val="num1_1"
                                                :duration="num1_1 - num1_0 > 10 ? duration / (num1_1 - num1_0) : 10"></vue-count-to> -->
                                        </div>
                                        <div class="title">入场观众</div>
                                    </div>
                                </div>
                                <div class="d2">
                                    <img src="@/assets/img/entrance/icon_2.png" />
                                    <div>
                                        <div class="num">
                                            {{num2_1}}
                                            <!-- <vue-count-to :start-val="num2_0" :end-val="num2_1"
                                                :duration="num2_1 - num2_0 > 10 ? duration / (num2_1 - num2_0) : 10"></vue-count-to> -->
                                        </div>
                                        <div class="title">入场工作人员</div>
                                    </div>
                                </div>
                            </div>
                            <div class="box box2 MT10">
                                <div class="box-title">
                                    <h4>入场通道</h4>
                                    <div class="desc">Access Tunnel</div>
                                </div>
                                <div id="chart1"></div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :md="12">
                        <div class="group box box3">
                            <div class="box-title">
                                <h4>地域分布</h4>
                                <div class="desc">Geographical distribution</div>
                            </div>
                            <div id="chart2"></div>
                            <div class="list-wrap list-wrap-1">
                                <div class="tr">
                                    <div class="th">省份/直辖市</div>
                                    <div class="th">人数</div>
                                </div>
                                <div class="list-content" ref="listContent1">
                                    <vue-seamless-scroll v-if="scrollOption1 && chart2Data" :data="chart2Data" :class-option="scrollOption1" class="list-box">
                                        <div v-for="(item,index) in chart2Data" :key="index" class="tr">
                                            <div class="td">{{item.name}}</div>
                                            <div class="td"><strong>{{item.count}}</strong></div>
                                        </div>
                                    </vue-seamless-scroll>
                                    <div v-else class="list-box">
                                        <div v-for="(item,index) in chart2Data" :key="index" class="tr">
                                            <div class="td">{{item.name}}</div>
                                            <div class="td"><strong>{{item.count}}</strong></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-col>
            <el-col :lg="12">
                <div class="group box">
                    <div class="box-title">
                        <h4>秀动验票-验票分钟趋势</h4>
                        <div class="desc">Showstart inspect ticket</div>
                    </div>
                    <div id="chart3"></div>
                    <div class="box-title">
                        <h4>秀动验票-验票明细</h4>
                        <div class="desc">Ticket inspection details</div>
                    </div>
                    <div class="list-wrap list-wrap-2">
                        <!-- <div class="tr">
                            <div class="th">验票时间</div>
                            <div class="th">进场人</div>
                            <div class="th">票码</div>
                            <div class="th">验票设备</div>
                            <div class="th">设备终端</div>
                            <div class="th">验票方式</div>
                            <div class="th">工作人员</div>
                        </div> -->
                        <div class="list-content" ref="listContent2">
                            <vue-seamless-scroll v-if="scrollOption2 && listData2" :data="listData2" :class-option="scrollOption2" class="list-box">
                                <!-- <div v-for="(item,index) in listData2" :key="index" class="tr">
                                    <div class="td">{{item.checkTime}}</div>
                                    <div class="td">{{item.customerName}}</div>
                                    <div class="td">{{item.ticketCode}}</div>
                                    <div class="td">{{item.deviceTypeName}}</div>
                                    <div class="td">{{item.deviceName}}</div>
                                    <div class="td">{{item.checkTypeName}}</div>
                                    <div class="td">{{item.staffName}}</div>
                                </div> -->
                                <div class="list-group">
                                    <div v-for="(item,index) in listData2" :key="index" class="item">
                                        <div class="avatar"><el-image :src="item.customerAvatar" fit="cover"></el-image></div>
                                        <div class="info">
                                            <p>姓名：{{item.customerName}}</p>
                                            <p>证件号：{{item.customerCertNo}}</p>
                                            <p>入场通道：{{item.deviceTypeName}}</p>
                                            <p>入场时间：{{item.checkTime}}</p>
                                        </div>
                                    </div>
                                </div>
                            </vue-seamless-scroll>
                            <div v-else class="list-box">
                                <!-- <div v-for="(item,index) in listData2" :key="index" class="tr">
                                    <div class="td">{{item.checkTime}}</div>
                                    <div class="td">{{item.customerName}}</div>
                                    <div class="td">{{item.ticketCode}}</div>
                                    <div class="td">{{item.deviceTypeName}}</div>
                                    <div class="td">{{item.deviceName}}</div>
                                    <div class="td">{{item.checkTypeName}}</div>
                                    <div class="td">{{item.staffName}}</div>
                                </div> -->
                                <div class="list-group">
                                    <div v-for="(item,index) in listData2" :key="index" class="item">
                                        <div class="avatar"><el-image :src="item.customerAvatar" fit="cover"></el-image></div>
                                        <div class="info">
                                            <p>姓名：{{item.customerName}}</p>
                                            <p>证件号：{{item.customerCertNo}}</p>
                                            <p>入场通道：{{item.deviceTypeName}}</p>
                                            <p>入场时间：{{item.checkTime}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { Chart } from '@antv/g2';
// import VueCountTo from 'vue-count-to'
import VueSeamlessScroll from 'vue-seamless-scroll'
import cookie from 'js-cookie'
export default {
    components: { 
        // VueCountTo, 
        VueSeamlessScroll
    },
    data() {
        return {
            dataLoading: null,
            duration: 60000,

            num1_0: 0,
            num1_1: 0,
            num2_0: 0,
            num2_1: 0,

            chart1: null,
            chart1Data: null,

            chart2: null,
            chart2Data: null,

            chart3: null,
            chart3Data: null,
            listData2: [],

            scrollOption1: null,
            scrollOption2: null,

            data: null,

            timer: null,

            nowTime: "",
        };
    },
    created() {
        this.dataLoading = this.$loading({
            lock: true,
            text: '加载中...',
            background: 'rgba(225, 225, 225, 0.9)'
        });
    },
    mounted() {
        this.getData();
        // window.onresize = () => {
        //     console.log(this.timer)
        //     if( !this.timer ){
        //         this.timer = setTimeout(() => {
        //             clearTimeout(this.timer);
        //             this.timer = null;
        //             window.location.reload();
        //         }, 1000)
        //     }
        // }

        this.getTime();
    },
    methods: {
        // 获取当前时间
        getTime(){
            this.nowTime = this.$utils.formatDate( new Date(), 'yyyy年MM月dd日 HH:mm:ss' );
            let _timer = setTimeout(() => {
                //获取当前时间
                this.getTime();
                clearTimeout(_timer);
            }, 1000)
        },

        //获取数据
        async getData() {

            const res =  await this.$request({
                url: "/daping/stadata",
                method: "POST",
                params: {
                    secretkey: cookie.get('secretKey')
                },
            }).finally(() => {
                this.dataLoading.close();
            })

            const {state, msg, result} = res.data;
            if(state && result){
                this.data = result;
                //入场人数
                this.num1_1 = result.customerCount;
                this.num2_1 = result.staffCount;
                //入场通道
                this.chart1Data = result.entrySta ? [...result.entrySta] : [];
                if (this.chart1) {
                    this.chart1.changeData(this.chart1Data);
                } else {
                    this.drawChart1();
                }
                //地域分布
                this.chart2Data = result.areaSta ? [...result.areaSta] : [];
                if (this.chart2) {
                    this.chart2.changeData(this.chart2Data);
                } else {
                    this.drawChart2();
                }
                //验票趋势
                this.chart3Data =  result.timeSta ? [...result.timeSta] : [];
                if (this.chart3) {
                    this.chart3.changeData(this.chart3Data);
                } else {
                    this.drawChart3();
                }
                //验票明细
                this.listData2 = result.lastCheckInfo ? [...result.lastCheckInfo] : [];

                this.$nextTick(() => {
                    const _ch1 = this.$refs['listContent1'] && this.$refs['listContent1'].offsetHeight;
                    const _ci1 = this.chart2Data.length * 40;
                    if(_ci1 > _ch1){
                        this.scrollOption1 = {
                            autoPlay: true,
                            step: 0.4, // 数值越大速度滚动越快
                            limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                            hoverStop: true, // 是否开启鼠标悬停stop
                            direction: 1, // 0向下 1向上 2向左 3向右
                            openWatch: true, // 开启数据实时监控刷新dom
                            singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                            waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                        }
                    }

                    const _ch2 = this.$refs['listContent2'] && this.$refs['listContent2'].offsetHeight;
                    const _ci2 = this.listData2.length * 120;
                    if(_ci2 > _ch2){
                        this.scrollOption2 = {
                            autoPlay: true,
                            step: 1, // 数值越大速度滚动越快
                            limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
                            hoverStop: true, // 是否开启鼠标悬停stop
                            direction: 1, // 0向下 1向上 2向左 3向右
                            openWatch: true, // 开启数据实时监控刷新dom
                            singleHeight: 120, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                            singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                            waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
                        }
                    }
                })

            }else{
                if( state == 'daping.tologin' ){
                    this.$router.replace('/entrance/login')
                }else{
                    this.$message.error(msg || '请求错误');
                }
            }

            let dataTimer = setTimeout(() => {
                this.num1_0 = this.num1_1;
                this.num2_0 = this.num2_1;
                this.getData();
                clearTimeout(dataTimer);
            }, this.duration);
        },

        //绘制入场通道
        drawChart1() {
            this.chart1 = new Chart({
                container: 'chart1',
                autoFit: true,
                appendPadding: [40, 0, 10, 0],
            });
            this.chart1.data(this.chart1Data);
            this.chart1.tooltip({
                showMarkers: false,
            });
            this.chart1.axis('count',{
                grid:{
                    line:{
                        style:{
                            stroke: '#eeeeee'
                        },
                    }
                }
            })
            this.chart1.scale('count', {
                alias: '入场人数',
                nice: true,
            });
            this.chart1.interval()
                .position('name*count')
                .style({
                    fill: "#0670FC"
                })
                .label('count', {
                    style: {
                        fill: "#0670FC",
                    },
                    content: (originData) => {
                        return originData.count;
                    }
                })


            this.chart1.render();
        },
        //绘制地域分布
        drawChart2() {
            this.chart2 = new Chart({
                container: 'chart2',
                autoFit: true,
                height: 240,
                appendPadding: [40, 0, 40, 0],
            });
            this.chart2.data(this.chart2Data);
            this.chart2.tooltip({
                showMarkers: false,
                showTitle: false,
            });
            this.chart2.legend(false);
            this.chart2.coordinate('theta', {
                // radius: 1,
                innerRadius: 0.55,
            });
            this.chart2.interval()
                .adjust('stack')
                .position('count')
                .color('name')
                .label('count', () => {
                    return {
                        style: {
                            fontSize: 10,
                        },
                        content: (originData) => {
                            return originData.name + ':' + originData.count;
                        }
                    }
                })
            this.chart2.render();
        },
        //绘制秀动验票-验票分钟趋势
        drawChart3(){
            this.chart3 = new Chart({
                container: 'chart3',
                autoFit: true,
                height: 240,
                appendPadding: [20, 0, 40, 0],
            });
            this.chart3.data(this.chart3Data);

            this.chart3.scale('name', {
                range: [0, 1],
                tickCount: 10,
                // type: 'timeCat'
            });
            this.chart3.scale('count', {
                alias: '入场人数',
                nice: true,
            });

            this.chart3.axis('count',{
                grid:{
                    line:{
                        style:{
                            stroke: '#eeeeee'
                        },
                    }
                }
            })

            this.chart3.tooltip({
                showCrosshairs: true,
            });
            
            this.chart3.line()
                .position('name*count')
                .color('#0670FC')
            this.chart3.area()
                .position('name*count')
                .color('l(100) 0:#0670FC 1:#ffffff');

            this.chart3.render();
        }
    },
}
</script>
<style lang="scss" scoped>
.data-container {
    padding: 0 20px 0 20px;
    background: #EBEFFF;
    overflow: hidden;
    .data-head{
        line-height: 1;
        height: 90px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #737B86;
        background: url("~@/assets/img/entrance/top.png") center center no-repeat;
        background-size: cover;
        margin: 0 -20px;
        padding: 0 20px;
        padding-top: 20px;
        .data-head-title{
            flex: 1;
        }
        .data-head-time{
            flex: 1;
            text-align: right;
        }
        .data-head-name{
            font-size: 18px;
            font-weight: 600;
            width: 40%;
            text-align: center;
            padding: 0 10px;
            color: #000;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: normal;
        }
    }
    @media screen and (max-width: 640px){
        .data-head{
            flex-direction: column;
            align-items: center;
            .data-head-name{
                font-size: 14px;
                width: 100%;
            }
        }
    }

    .statistics-head{
        margin-bottom: 10px;
        background: #fff;
        border-radius: 10px;
        .statistics-item{
            height: 90px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .num{
                font-size: 16px;
                font-weight: 600;
                color: $--color-theme;
            }
            .name{
                font-size: 12px;
                margin-top: 5px;
            }
        }
    }

    .group {
        height: calc(100vh - 210px);
        margin-bottom: 20px;
    }

    .box {
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        padding: 40px 30px;
        .box-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 1;

            h4 {
                font-size: 18px;
                font-weight: 600;
            }

            .desc {
                font-size: 12px;
                color: #BBBBBB;
            }
        }
    }
    @media screen and (max-width: 640px){
        .box {
            padding: 40px 10px;
        }
    }

    .box1 {
        padding: 0;

        >div {
            padding: 40px 30px;
            display: flex;
            align-items: center;
            font-size: 12px;

            img {
                width: 60px;
                height: 60px;
                margin-right: 20px;
            }

            .num{
                font-size: 24px;
                font-weight: 600;
            }

            .title {
                margin-top: 5px;
            }
        }

        .d1 {
            background: #0670FC;
            color: #fff;
            border-radius: 10px;
        }
    }

    .box2 {
        height: calc(100% - 290px);

        #chart1 {
            height: calc(100% - 8px);
        }
    }

    .list-wrap{
        .tr{
            display: flex;
            justify-content: space-between;
            .th,.td{
                padding: 14px 0;
                font-size: 12px;
                // height: 40px;
                line-height: 1;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .th{
                color: #5A687B;
            }
        }
        .list-content{
            overflow: hidden;
            position: relative;
            height: calc(100% - 40px);
            .list-box{
                height: 100%;
                // .tr{
                //     &:nth-child(odd){
                //         background: #F7F9FD;
                //     }
                // }
                // >div > div:nth-child(even){
                //     .tr{
                //         &:nth-child(odd){
                //             background: #fff;
                //         }
                //         &:nth-child(even){
                //             background: #F7F9FD;
                //         }
                //     }
                // }
            }
        }
    }

    .list-wrap-1{
        height: calc(100% - 18px - 240px);
    }
    .list-wrap-2{
        margin-top: 20px;
        height: calc(100% - 36px - 240px - 10px);
        // .th,.td{
        //     flex:1;
        //     margin-right: 10px;
        //     &:first-child{
        //         width: 8%;
        //         flex: 1 auto;
        //     }
        //     &:last-child{
        //         margin-right: 0;
        //     }
        // }
        .list-content{
            height: 100%;
        }

        .list-group{
            display: flex;
            flex-wrap: wrap;
            .item{
                width: 50%;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .avatar{
                    width: 80px;
                    height: 100px;
                    background: #f8f8f8;
                    margin-right: 16px;
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
                .info{
                    flex: 1;
                    font-size: 11px;
                    p{
                        margin: 5px 0;
                    }
                }
            }
            @media screen and (max-width: 640px){
                .item {
                    width: 100%;
                }
            }
        }
    }
}
strong{
    font-weight: 600;
}
</style>